import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

/**
 * Session storage service
 */
@Injectable()
export class SessionStorageService extends StorageService {
	constructor() {
		super(sessionStorage);
	}
}

export const STORAGE_KEYS = {
	ADDRESS_COMP: 'addressComp',
	PARTNER_REFERRAL: 'partnerReferral',
	DEBIT_CARD_RETRIES: 'debitCardRetries',
	REF_ID: 'refId',
	APP_SOURCE: 'app-source',
	PLAID_CONNECTED: 'plaidConnected',
	HOLIDAY_REFERRAL_ENABLED: 'isHolidayReferralEnable',
	PLAID_CONNECT_ADDRESS_SCREEN_ORDER_SWAP_ENABLED: 'plaidConnectAddressScreenOrderSwapEnabled',
	AGENT_STORE_CHANNEL: 'agentStoreChannel',
	REMOVE_LOCATION_LINK: 'removeLocationLink'
};
