import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { SectionTypeEnum } from 'src/app/core/services/plaid-link/plaid-link.model';
import { BankConnectEventTypeEnum, PlaidLinkService } from 'src/app/core/services/plaid-link/plaid-link.service';

import { opRequired } from '../../decorators/required.decorator';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
/**
 * Display button to open plaid
 *
 * @export
 * @class PlaidComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
	selector: 'op-plaid-button',
	templateUrl: './plaid-button.component.html',
	styleUrls: ['./plaid-button.component.scss']
})
export class PlaidButtonComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	bankOnly: boolean = false;

	@Input()
	sectionType: SectionTypeEnum;

	@Input()
	bankAccountExists: boolean;

	@Input()
	color: string = 'primary';

	@Input()
	plaidConnectForIncomeVerified: boolean = false;

	private subscription = new Subscription();
	bankConnectState: BankConnectEventTypeEnum;

	constructor(
		private loanAppService: LoanApplicationService,
		private plaidLinkService: PlaidLinkService,
		private router: Router,
		private routingService: RoutingService
	) {}

	ngOnInit(): void {
		let sessionStorageData = this.plaidLinkService.getPlaidSessionData();
		const applicant = this.loanAppService.getCurrentApplicant();
		const eligible = applicant.incomeVerificationEligible || applicant.bankAccountVerificationEligible;

		if (!sessionStorageData?.isOauthFlow) {
			this.initializePlaid(this.bankOnly, eligible);
		} else {
			this.initializePlaidOauth(this.bankOnly, eligible);
		}

		const plaidSub = this.plaidLinkService.plaid$.subscribe({
			next: (rsp) => {
				this.bankConnectState = rsp.type;
			}
		});
		this.subscription.add(plaidSub);
	}

	get isBankConnectAvailable(): boolean {
		const states = [BankConnectEventTypeEnum.loading, BankConnectEventTypeEnum.idle, BankConnectEventTypeEnum.waiting];
		return states.some((item) => item === this.bankConnectState);
	}

	private initializePlaid(bankOnly: boolean, eligible: boolean): void {
		this.plaidLinkService.initializePlaid(bankOnly, eligible, this.routingService.getCurrentRoute());
	}

	private initializePlaidOauth(bankOnly: boolean, eligible: boolean): void {
		this.plaidLinkService.initAndOpenPlaidOauth(bankOnly, eligible, this.routingService.getCurrentRoute());
	}

	openPlaid(): void {
		this.plaidLinkService.openPlaid(this.sectionType, this.router.url, this.routingService.getCurrentRoute());
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
