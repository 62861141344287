import { HttpEventType } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { find, findIndex, includes, isEmpty } from 'lodash';
import { BehaviorSubject, defer, forkJoin, Observable, of, Subscription, TimeoutError, timer } from 'rxjs';
import {
	catchError,
	concatMap,
	distinctUntilChanged,
	exhaustMap,
	filter,
	finalize,
	first,
	map,
	pairwise,
	retry,
	switchMap,
	take,
	tap,
	timeout
} from 'rxjs/operators';
import { incomeBankStatementCriteria } from 'src/app/approval/document-submit/auto-verification/auto-verification-utils';
import { v4 as uuidv4 } from 'uuid';

import { AuthenticationService } from '../authentication/authentication.service';
import { LanguageService } from '../language/language.service';
import { LoanApplicationService } from '../loan-application/loan-application.service';
import {
	ConfigApiService,
	DocumentSideEnum,
	FileStatusEnum,
	FileTypeEnum,
	IDocumentStatus,
	IUploadDocumentV2Result,
	IUploadedFiles
} from '../mobile-api';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import { SessionStorageService } from '../storage/session-storage.service';
import { readFile, renderDocument, renderImage } from './thumbnail/thumbnail';

export enum ThumbnailSizeEnum {
	small = 'small',
	large = 'large'
}

export enum FileUploadStatusEnum {
	pending = 'PENDING',
	started = 'STARTED',
	uploading = 'UPLOADING',
	finished = 'FINISHED',
	cancelled = 'CANCELLED',
	error = 'ERROR'
}

export enum FileSideEnum {
	front = 'front',
	back = 'back'
}

export enum FileUploadType {
	bank = 'bank',
	income = 'income',
	addresses = 'addresses',
	identification = 'identification',
	vehicleRegistration = 'vehicleRegistration',
	vehiclePhotos = 'vehiclePhotos',
	vehicleDriversLicense = 'vehicleDriversLicense',
	dmvRequiredId = 'dmvRequiredId',
	selfie = 'selfie',
	bounced = 'bounced',
	all = 'all'
}

export interface IFileUpload {
	type: FileUploadType;
	id: string;
	name: string;
	file: File;
	thumbnail?: string;
	progress?: number;
	error?: any;
	status?: FileUploadStatusEnum;
	ocrInfo?: IUploadedFiles;
	classification?: number | string;
	side: FileSideEnum;
	country?: string;
	createdDate?: number;
}

export interface IFileUploadOptions {
	classification?: number | string;
	side?: FileSideEnum;
	id?: string;
	country?: string;
}

export interface IFileUploads {
	[type: string]: IFileUpload[];
}

function finalizeWithValue<T>(callback: (value: T) => void) {
	return (source: Observable<T>) =>
		defer(() => {
			let lastValue: T;
			return source.pipe(
				tap((value) => (lastValue = value)),
				finalize(() => callback(lastValue))
			);
		});
}

/**
 * Manage the file upload process.
 *
 * @export
 * @class FileUploadService
 */
@Injectable({
	providedIn: 'root'
})
export class FileUploadService implements OnDestroy {
	private readonly fileUploadSource = new BehaviorSubject<IFileUploads>(null);
	private readonly currentFileTypeUploadSource = new BehaviorSubject<FileUploadType[]>(null);

	// Exposed observable (read-only).
	readonly fileUploads$ = this.fileUploadSource.asObservable();
	readonly currentFileTypeUploads$ = this.currentFileTypeUploadSource.asObservable();
	private readonly storageKey = 'fileUploads';
	private readonly currentFileTypeStorageKey = 'fileTypeUploads';

	private readonly subscription = new Subscription();

	private frequency = 5000;
	private duration = 120000;

	constructor(
		private loanAppService: LoanApplicationService,
		private mobileService: MobileApiService,
		private authService: AuthenticationService,
		private sessionStorageService: SessionStorageService,
		private configApiService: ConfigApiService,
		private languageService: LanguageService
	) {
		const frequency$ = this.configApiService.configOcrPollingFrequency();
		const duration$ = this.configApiService.configOcrPollingDuration();
		const configSub = forkJoin({
			frequency: frequency$,
			duration: duration$
		}).subscribe({
			next: (rsp) => {
				this.frequency = Number(rsp.frequency?.value) * 1000 || this.frequency;
				this.duration = Number(rsp.duration?.value) * 1000 || this.duration;
			}
		});
		this.subscription.add(configSub);

		const sessionData = this.sessionStorageService.get(this.storageKey);
		const fileData = sessionData || {};
		const uploadData: IFileUploads = {};

		Object.keys(fileData).forEach((key) => {
			uploadData[key] = fileData[key].filter((file) => {
				return (
					file.status === FileUploadStatusEnum.finished ||
					(file.status === FileUploadStatusEnum.pending && key === FileUploadType.selfie)
				);
			});
		});
		this.fileUploadSource.next(uploadData);

		const fileSub = this.fileUploads$
			.pipe(
				filter((files) => this.anyFilesOcrPending(files)),
				exhaustMap(() => this.pollUploadStatus()),
				map((r) => this.getFileUploads())
			)
			.subscribe({
				next: (files: IFileUploads) => {
					// use setTimeout to allow pollUploadStatus to finalize.
					setTimeout(() => {
						this.anyFilesOcrPending(files) && this.fileUploadSource.next(files);
					}, 5);
				},
				error: (err) => {
					console.log('error:', err);
				}
			});
		this.subscription.add(fileSub);

		const langSub = this.languageService.langChanges$
			.pipe(
				distinctUntilChanged(),
				filter(() => this.anyFilesOcrReview(this.getFileUploads())),
				switchMap(() => this.updateOcrReviewMessage())
			)
			.subscribe();
		this.subscription.add(langSub);

		this.sessionStorageService.select(this.currentFileTypeStorageKey).subscribe({
			next: (typeUploads: FileUploadType[]) => {
				this.currentFileTypeUploadSource.next(typeUploads || []);
			}
		});

		// Clear out data if no token
		this.authService.authorization$
			.pipe(
				map((rsp) => rsp?.token),
				map((token) => !token && this.setFileUploads({}))
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private anyFilesOcrPending(files: IFileUploads): boolean {
		return Object.keys(files)?.some((type) => {
			return files[type].some((file) => file.ocrInfo?.documentStatus === FileStatusEnum.pending);
		});
	}

	private anyFilesOcrReview(files: IFileUploads): boolean {
		return Object.keys(files)?.some((type) => {
			return files[type].some((file) => file.ocrInfo?.documentStatus === FileStatusEnum.review);
		});
	}

	/**
	 * Get the current file uploads object.
	 *
	 * @return {*}  {IFileUploads}
	 * @memberof FileUploadService
	 */
	getFileUploads(): IFileUploads {
		return this.fileUploadSource.getValue();
	}

	getCurrentFileTypeUploads(): FileUploadType[] {
		return this.currentFileTypeUploadSource.getValue();
	}

	/**
	 * Checks to see if file upload has started
	 *
	 * @param {IFileUpload} file
	 * @return {*}  {boolean}
	 * @memberof FileUploadService
	 */
	isFileUploadStarted(file: IFileUpload): boolean {
		const startedMap = [FileUploadStatusEnum.started, FileUploadStatusEnum.uploading, FileUploadStatusEnum.finished];
		return startedMap.some((item) => item === file?.status);
	}

	isFileUploadFinished(file: IFileUpload): boolean {
		return file?.status === FileUploadStatusEnum.finished;
	}

	isFileUploadPending(file: IFileUpload): boolean {
		return file?.status === FileUploadStatusEnum.pending;
	}

	isFileUploadUploading(file: IFileUpload): boolean {
		return file?.status === FileUploadStatusEnum.uploading;
	}

	isAllFilesUploadFinished(files: IFileUpload[]): boolean {
		return files ? files.every((file) => this.isFileUploadFinished(file)) : false;
	}

	isSomeFilesUploadFinished(files: IFileUpload[]): boolean {
		return files ? files.some((file) => this.isFileUploadFinished(file)) : false;
	}

	isSomeFilesUploadPending(files: IFileUpload[]): boolean {
		return files ? files.some((file) => this.isFileUploadPending(file)) : false;
	}

	isSomeFilesUploadUploading(files: IFileUpload[]): boolean {
		return files ? files.some((file) => this.isFileUploadUploading(file)) : false;
	}

	isFileUploadNotStarted(file: IFileUpload): boolean {
		const startedMap = [FileUploadStatusEnum.pending, FileUploadStatusEnum.error];
		return startedMap.some((item) => item === file?.status);
	}

	isAllFilesUploadNotStarted(files: IFileUpload[]): boolean {
		return files ? files.every((file) => this.isFileUploadNotStarted(file)) : true;
	}

	isSomeFileTypeUploadFinished(type: FileUploadType, fileUploads: IFileUploads = null): boolean {
		const files = isEmpty(fileUploads) ? this.getFileUploads()[type] : fileUploads[type];
		return this.isSomeFilesUploadFinished(files);
	}

	isSomeFileTypeUploadPending(type: FileUploadType, fileUploads: IFileUploads = null): boolean {
		const files = isEmpty(fileUploads) ? this.getFileUploads()[type] : fileUploads[type];
		return this.isSomeFilesUploadPending(files);
	}

	/**
	 * Set the started status
	 *
	 * @private
	 * @param {IFileUpload} file
	 * @param {*} event
	 * @return {*}  {IFileUpload}
	 * @memberof FileUploadService
	 */
	private updateStarted(file: IFileUpload, event: any): IFileUpload {
		return { ...file, progress: 0, status: FileUploadStatusEnum.started };
	}

	/**
	 * Set the progress %
	 *
	 * @private
	 * @param {IFileUpload} file
	 * @param {*} event
	 * @return {*}  {IFileUpload}
	 * @memberof FileUploadService
	 */
	private updateProgress(file: IFileUpload, event: any): IFileUpload {
		return {
			...file,
			progress: Math.round((100 * event.loaded) / event.total),
			status: FileUploadStatusEnum.uploading
		};
	}

	private updateHeader(file: IFileUpload, event: any): void {
		// This is intentional empty
	}

	private updateDownloadProgress(file: IFileUpload, event: any): void {
		// This is intentional empty
	}

	/**
	 * set the complete status
	 *
	 * @private
	 * @param {IFileUpload} file
	 * @param {*} event
	 * @return {*}  {IFileUpload}
	 * @memberof FileUploadService
	 */
	private updateComplete(file: IFileUpload, event: any): IFileUpload {
		return {
			...file,
			progress: 100,
			status: FileUploadStatusEnum.finished
		};
	}

	private updateUser(file: IFileUpload, event: any): void {
		// This is intentional empty
	}

	/**
	 * Set the cancelled status if progress has not completed.
	 *
	 * @private
	 * @param {IFileUpload} file
	 * @param {*} event
	 * @return {*}  {IFileUpload}
	 * @memberof FileUploadService
	 */
	private updateFinalized(file: IFileUpload, event: any): IFileUpload {
		const info = this.getFileUploads();
		const typeList = info[file.type];
		const newFile = { ...file };
		const curr = typeList.find((item) => item.id === file.id);
		if (curr.status === FileUploadStatusEnum.uploading) {
			newFile.status = FileUploadStatusEnum.cancelled;
		}
		return newFile;
	}

	/**
	 * Set the error status.
	 *
	 * @private
	 * @param {IFileUpload} file
	 * @param {*} event
	 * @return {*}  {IFileUpload}
	 * @memberof FileUploadService
	 */
	private updateError(file: IFileUpload, event: any): IFileUpload {
		const newFile = { ...file };
		newFile.status = FileUploadStatusEnum.error;
		newFile.error = event.error;
		return newFile;
	}

	/**
	 * Find an item, merge then replace it with an updated item.
	 *
	 * @private
	 * @param {IFileUpload[]} typeList
	 * @param {IFileUpload} file
	 * @return {*}  {IFileUpload[]}
	 * @memberof FileUploadService
	 */
	private replaceItem(typeList: IFileUpload[], file: IFileUpload): IFileUpload[] {
		const removeElementIndex = findIndex(typeList, ['id', file.id]);
		const element = find(typeList, ['id', file.id]);
		const createdDate = element?.createdDate || file?.createdDate;
		const newElement = { ...element, ...file, createdDate };
		if (removeElementIndex >= 0) {
			return [...typeList.slice(0, removeElementIndex), newElement, ...typeList.slice(removeElementIndex + 1)];
		} else {
			return [...typeList, file];
		}
	}

	/**
	 * Find an item, remove it from the list and return the remaining list.
	 *
	 * @private
	 * @param {IFileUpload[]} typeList
	 * @param {IFileUpload} file
	 * @return {*}  {IFileUpload[]}
	 * @memberof FileUploadService
	 */
	private removeItem(typeList: IFileUpload[], file: IFileUpload): IFileUpload[] {
		const removeElementIndex = findIndex(typeList, ['id', file.id]);
		if (removeElementIndex >= 0) {
			return [...typeList.slice(0, removeElementIndex), ...typeList.slice(removeElementIndex + 1)];
		} else {
			return typeList;
		}
	}

	/**
	 * Update the behavior subject with latest file information.
	 *
	 * @private
	 * @param {IFileUpload} file
	 * @memberof FileUploadService
	 */
	private updateSource(file: IFileUpload): void {
		const info = this.getFileUploads();
		let update: IFileUploads;
		const typeList = info[file.type];

		if (typeList) {
			update = { ...info, [file.type]: this.replaceItem(typeList, file) };
		} else {
			update = { ...info, [file.type]: [file] };
		}

		this.setFileUploads(update);
	}

	/**
	 * Remove the behavior subject with latest file information.
	 *
	 * @private
	 * @param {IFileUpload} file
	 * @memberof FileUploadService
	 */
	private removeSource(file: IFileUpload): void {
		const info = this.getFileUploads();
		let update: IFileUploads;
		const typeList = info[file.type];

		if (typeList) {
			update = { ...info, [file.type]: this.removeItem(typeList, file) };
		} else {
			update = info;
		}

		this.setFileUploads(update);
	}

	/**
	 * Set the next value of the BehaviorSubject.
	 *
	 * @private
	 * @param {IFileUploads} files
	 * @memberof FileUploadService
	 */
	private setFileUploads(files: IFileUploads): void {
		this.sessionStorageService.set(this.storageKey, files);
		this.fileUploadSource.next(files);
	}

	/**
	 * Add the thumbnail to the file info.
	 *
	 * @private
	 * @param {IFileUpload} fileInfo
	 * @param {File} file
	 * @memberof FileUploadService
	 */
	private addThumbnail(fileInfo: IFileUpload, fileData: any): void {
		// get the latest file info to add the thumbnail
		const info = this.getFileUploads();
		const typeList = info[fileInfo.type];
		const curr =
			Array.isArray(typeList) && !isEmpty(typeList) ? typeList.find((item) => item.id === fileInfo.id) || null : null;
		curr && this.updateSource({ ...curr, thumbnail: fileData, name: fileInfo.name });
	}

	/**
	 * Update the thumbnail to the file info.
	 *
	 * @private
	 * @param {IFileUpload} fileInfo
	 * @param {File} file
	 * @memberof FileUploadService
	 */
	private updateThumbnail(fileInfo: IFileUpload, fileData: any): void {
		this.updateSource({ ...fileInfo, thumbnail: fileData });
	}

	/**
	 * Create the initial file information.
	 *
	 * @private
	 * @param {FileUploadType} type
	 * @param {File} file
	 * @return {*}  {IFileUpload}
	 * @memberof FileUploadService
	 */
	private createFileInfo(type: FileUploadType, file: File, options: IFileUploadOptions = null): IFileUpload {
		return {
			type,
			file,
			createdDate: Date.now(),
			classification: options?.classification,
			side: options?.side,
			country: options?.country,
			id: uuidv4(),
			name: file.name,
			status: FileUploadStatusEnum.pending
		};
	}

	/**
	 * Use the file info to create the formData to send to the BE.
	 *
	 * @private
	 * @param {IFileUpload} fileInfo
	 * @return {*}  {FormData}
	 * @memberof FileUploadService
	 */
	private createFormData(fileInfo: IFileUpload): FormData {
		const formData = new FormData();
		if (fileInfo.file instanceof Blob) {
			formData.append(fileInfo.name, fileInfo.file, fileInfo.name);
		}
		return formData;
	}

	/**
	 * Attach multiple files and JSON data to the formData V2.
	 *
	 * @private
	 * @param {IFileUpload[]} fileInfos
	 * @return {*}  {FormData}
	 * @memberof FileUploadService
	 */
	private createMultiFormData(fileInfos: IFileUpload[]): FormData {
		const formData = new FormData();
		fileInfos.forEach((fileInfo: IFileUpload, index: number) => {
			this.appendFormData(fileInfo, formData);
			if (index === 0) {
				this.appendJsonFormData(fileInfo, formData);
			}
		});
		return formData;
	}
	/**
	 * Append another file to the current formData V2
	 *
	 * @private
	 * @param {IFileUpload} fileInfo
	 * @param {FormData} formData
	 * @return {*}  {FormData}
	 * @memberof FileUploadService
	 */
	private appendFormData(fileInfo: IFileUpload, formData: FormData): FormData {
		if (fileInfo?.file instanceof Blob) {
			formData?.append(fileInfo.side === FileSideEnum.back ? 'fileBack' : 'fileFront', fileInfo.file, fileInfo.name);
		}
		return formData;
	}

	/**
	 * Append JSON data to formData for V2 api
	 *
	 * @private
	 * @param {IFileUpload} fileInfo
	 * @param {FormData} formData
	 * @return {*}  {FormData}
	 * @memberof FileUploadService
	 */
	private appendJsonFormData(fileInfo: IFileUpload, formData: FormData): FormData {
		const loanAppKeyMap = {
			type: 'documentType',
			classification: 'documentClassification',
			country: 'country'
		};

		if (fileInfo) {
			Object.keys(fileInfo).forEach((key) => {
				const loanAppKey = loanAppKeyMap[key];
				if (loanAppKey) {
					if (loanAppKey === 'documentType') {
						this.appendKeyFormData(loanAppKey, FileTypeEnum[fileInfo[key]], formData);
					} else {
						fileInfo[key] && this.appendKeyFormData(loanAppKey, fileInfo[key], formData);
					}
				}
			});
		}
		return formData;
	}

	/**
	 * Append a key/value pair to the formData for V2
	 *
	 * @private
	 * @param {string} key
	 * @param {string} value
	 * @param {FormData} formData
	 * @return {*}  {FormData}
	 * @memberof FileUploadService
	 */
	private appendKeyFormData(key: string, value: string, formData: FormData): FormData {
		formData?.append(key, value);
		return formData;
	}

	private getQueryParams(): any {
		return {};
	}

	private handleFileEvents(fileInfo: IFileUpload, event: any): IFileUpload {
		const uploadEvents = {
			[HttpEventType.Sent]: this.updateStarted.bind(this, fileInfo),
			[HttpEventType.UploadProgress]: this.updateProgress.bind(this, fileInfo),
			[HttpEventType.ResponseHeader]: this.updateHeader.bind(this, fileInfo),
			[HttpEventType.DownloadProgress]: this.updateDownloadProgress.bind(this, fileInfo),
			[HttpEventType.Response]: this.updateComplete.bind(this, fileInfo),
			[HttpEventType.User]: this.updateUser.bind(this, fileInfo),
			['finalized']: this.updateFinalized.bind(this, fileInfo),
			['error']: this.updateError.bind(this, fileInfo)
		};
		return uploadEvents[event.type](event);
	}

	private isSameSide(event: IUploadedFiles, file: IFileUpload): boolean {
		const docSideMap = {
			[FileSideEnum.front]: [DocumentSideEnum.front, DocumentSideEnum.none],
			[FileSideEnum.back]: [DocumentSideEnum.back]
		};
		return file.side ? docSideMap[file.side].includes(event?.documentSide) : true;
	}

	private handleFileEventsV2(files: IFileUpload[], events: any): IFileUpload[] {
		return files.map((file) => {
			const body: IUploadDocumentV2Result = events?.body;
			if (body?.uploadedFiles) {
				if (Array.isArray(body?.uploadedFiles)) {
					const event = body.uploadedFiles.find((event: IUploadedFiles) => {
						return this.isSameSide(event, file);
					});
					return {
						...this.handleFileEvents(file, events),
						ocrInfo: event,
						createdDate: event?.createdDate || file.createdDate
					};
				} else {
					return this.handleFileEvents(file, events);
				}
			} else {
				return this.handleFileEvents(file, events);
			}
		});
	}

	/**
	 * Render the thumbnail for the file.
	 *
	 * @private
	 * @param {IFileUpload} fileInfo
	 * @return {*}  {Observable<IFileUpload>}
	 * @memberof FileUploadService
	 */
	private renderThumbnail(fileInfo: IFileUpload): Observable<IFileUpload> {
		return readFile(fileInfo.file).pipe(
			switchMap((fileData) => {
				if (includes(fileInfo.file.type, 'image/')) {
					return renderImage(
						fileData,
						fileInfo.type === FileUploadType.selfie ? ThumbnailSizeEnum.large : ThumbnailSizeEnum.small
					);
				} else {
					return renderDocument(fileData);
				}
			}),
			take(1),
			map((info) => {
				this.addThumbnail(fileInfo, info);
				return fileInfo;
			})
		);
	}

	/**
	 * Save file types to session storage when they are uploaded.
	 * This is used to determine if the user has uploaded a file of a certain type in current session.
	 *
	 * @private
	 * @param {IFileUpload} file
	 * @memberof FileUploadService
	 */
	private saveTypeUploadToSession(file: IFileUpload): void {
		const typeUploads: FileUploadType[] = this.sessionStorageService.get(this.currentFileTypeStorageKey) || [];
		if (!typeUploads?.includes(file.type)) {
			this.sessionStorageService.set(this.currentFileTypeStorageKey, [...typeUploads, file.type]);
		}
	}

	/**
	 * Upload multiple files to the BE using V2 api
	 *
	 * @private
	 * @param {IFileUpload[]} files
	 * @param {FormData} formData
	 * @param {FileUploadType} type
	 * @return {*}  {Observable<IFileUpload[]>}
	 * @memberof FileUploadService
	 */
	private uploadFormData(
		files: IFileUpload[],
		formData: FormData,
		type: FileUploadType,
		showBusy: boolean = true
	): Observable<IFileUpload[]> {
		const setUploadDocument$ = this.mobileService.setUploadDocumentV2(
			formData,
			this.getQueryParams(),
			this.loanAppService.loanApplicationId,
			this.loanAppService.getCurrentApplicant().applicantIndex,
			showBusy
		);

		const setUploadPhoto$ = this.mobileService.setUploadPhotos(
			formData,
			this.getQueryParams(),
			this.loanAppService.loanApplicationId,
			this.loanAppService.getCurrentApplicant().applicantIndex,
			showBusy
		);

		return of(type === FileUploadType.selfie).pipe(
			switchMap((selfie) => (selfie ? setUploadPhoto$ : setUploadDocument$)),
			map((event) => {
				files.forEach((file) => (file.createdDate = Date.now()));
				return this.handleFileEventsV2(files, event);
			}),
			map((files: IFileUpload[]) => files.filter((f) => !isEmpty(f))),
			filter((updatedFiles: IFileUpload[]) => !isEmpty(updatedFiles)),
			map((updatedFiles: IFileUpload[]) => {
				return updatedFiles.map((file) => {
					file.createdDate = file.createdDate || Date.now();
					return file;
				});
			}),
			tap((updatedFiles: IFileUpload[]) => updatedFiles.forEach((file) => this.updateSource(file))),
			tap((updatedFiles: IFileUpload[]) => updatedFiles.forEach((file) => this.saveTypeUploadToSession(file))),
			catchError((err) => {
				const errorFiles = files.map((fileInfo) => {
					const file = this.handleFileEvents(fileInfo, {
						type: 'error',
						error: Array.isArray(err?.error) ? err.error.find(Boolean) : err.error
					});
					this.updateSource(file);
					return file;
				});
				return of(errorFiles);
			}),
			finalizeWithValue((fileEvent: IFileUpload[]) => {
				fileEvent.forEach((fileInfo) => {
					const file = this.handleFileEvents(fileInfo, { type: 'finalized' });
					this.updateSource(file);
				});
			})
		);
	}

	/**
	 * Remove the thumbnail from the existing list.
	 *
	 * @param {IFileUpload} fileInfo
	 * @memberof FileUploadService
	 */
	public removeThumbnail(fileInfo: IFileUpload): void {
		this.removeSource(fileInfo);
	}

	/**
	 * Send a file to the BE and report progress.
	 *
	 * @param {FileUploadType} type
	 * @param {File} file
	 * @return {*}  {Observable<IFileUpload>}
	 * @memberof FileUploadService
	 */
	public sendFile(type: FileUploadType, file: File, options: IFileUploadOptions = null): Observable<IFileUpload> {
		const fileInfo = this.createFileInfo(type, file, options);
		this.updateSource(fileInfo);
		this.renderThumbnail(fileInfo).subscribe();
		return this.uploadFile(fileInfo);
	}

	/**
	 * Save the thumbnail to the file info.
	 *
	 * @param {FileUploadType} type
	 * @param {File} file
	 * @param {IFileUploadOptions} [options=null]
	 * @return {*}  {Observable<IFileUpload>}
	 * @memberof FileUploadService
	 */
	public saveThumbnail(type: FileUploadType, file: File, options: IFileUploadOptions = null): Observable<IFileUpload> {
		const fileInfo = this.createFileInfo(type, file, options);
		fileInfo.id = options?.id ? options.id : fileInfo.id;
		this.updateSource(fileInfo);
		return this.renderThumbnail(fileInfo);
	}

	/**
	 * update the country of the saved file
	 *
	 * @param {IFileUpload} file
	 * @param {string} country
	 * @memberof FileUploadService
	 */
	public updateCountry(file: IFileUpload, country: string): IFileUpload {
		if (!isEmpty(file) && country) {
			const updatedFile = { ...file, country };
			this.updateSource(updatedFile);
			return updatedFile;
		}
		return file;
	}

	/**
	 * Send a file to the BE using V2 API
	 *
	 * @param {FileUploadType} type
	 * @param {File} file
	 * @param {IFileUploadOptions} [options=null]
	 * @return {*}  {Observable<IFileUpload>}
	 * @memberof FileUploadService
	 */
	public sendFileV2(
		type: FileUploadType,
		file: File,
		options: IFileUploadOptions = null,
		showBusy: boolean = true
	): Observable<IFileUpload> {
		const fileInfo = this.createFileInfo(type, file, options);
		this.updateSource(fileInfo);
		this.renderThumbnail(fileInfo).subscribe();
		return this.uploadFiles([fileInfo], showBusy).pipe(map((f) => f?.[0]));
	}

	/**
	 * upload documents using V2 API
	 *
	 * @param {IFileUpload[]} files
	 * @return {*}  {Observable<IFileUpload[]>}
	 * @memberof FileUploadService
	 */
	public uploadFiles(files: IFileUpload[], showBusy: boolean = true): Observable<IFileUpload[]> {
		if (!isEmpty(files)) {
			const formData = this.createMultiFormData(files);
			return this.uploadFormData(files, formData, files?.[0].type, showBusy);
		} else {
			return of([]); // TODO: should this be an error?
		}
	}

	/**
	 * Upload pending files using v2 API
	 *
	 * @param {FileUploadType} type
	 * @return {*}  {Observable<IFileUpload[]>}
	 * @memberof FileUploadService
	 */
	public uploadPendingFiles(type: FileUploadType, showBusy: boolean = true): Observable<IFileUpload[]> {
		const files = this.getFileUploads()[type];
		const pendingFiles = files?.filter((file) => file.status === FileUploadStatusEnum.pending);
		return this.uploadFiles(pendingFiles, showBusy);
	}

	/**
	 * upload a single file to the BE.
	 *
	 * @param {IFileUpload} fileInfo
	 * @return {*}  {Observable<IFileUpload>}
	 * @memberof FileUploadService
	 */
	public uploadFile(fileInfo: IFileUpload): Observable<IFileUpload> {
		const formData = this.createFormData(fileInfo);

		const setUploadDocument$ = this.mobileService.setUploadDocument(
			formData,
			this.getQueryParams(),
			this.loanAppService.loanApplicationId,
			this.loanAppService.getCurrentApplicant().applicantIndex
		);

		const setUploadPhoto$ = this.mobileService.setUploadPhotos(
			formData,
			this.getQueryParams(),
			this.loanAppService.loanApplicationId,
			this.loanAppService.getCurrentApplicant().applicantIndex
		);

		return of(fileInfo.type === FileUploadType.selfie).pipe(
			switchMap((selfie) => (selfie ? setUploadPhoto$ : setUploadDocument$)),
			map((event) => this.handleFileEvents(fileInfo, event)),
			filter((event) => Boolean(event)),
			tap(this.updateSource.bind(this)),
			catchError((err) => {
				const file = this.handleFileEvents(fileInfo, {
					type: 'error',
					error: Array.isArray(err?.error) ? err.error.find(Boolean) : err.error
				});
				this.updateSource(file);
				return of(file);
			}),
			finalizeWithValue((fileEvent) => {
				const file = this.handleFileEvents(fileEvent, { type: 'finalized' });
				this.updateSource(file);
			})
		);
	}

	public findFileById(id: string): IFileUpload {
		const files = this.getFileUploads();
		const keys = Object.keys(files);
		for (const key of keys) {
			const typeList = files[key];
			const file = typeList.find((file) => file.id === id);
			if (file) {
				return file;
			}
		}
		return null;
	}

	public removeSelectedFile(id: string): void {
		const file = this.findFileById(id);
		if (file?.status === FileUploadStatusEnum.pending) {
			this.removeSource(file);
		}
	}

	/**
	 * Removes all the pending files from service
	 *
	 * @param {FileUploadType} type
	 * @return {*}  {IFileUpload[]}
	 * @memberof FileUploadService
	 */
	public removePendingFiles(type: FileUploadType): IFileUpload[] {
		const files = this.getFileUploads()[type];
		const pendingFiles = files?.filter((file) => file.status === FileUploadStatusEnum.pending);
		pendingFiles?.forEach((file) => this.removeSource(file));
		return pendingFiles;
	}

	/**
	 * Removes all the error files from service
	 *
	 * @param {FileUploadType} type
	 * @return {*}  {IFileUpload[]}
	 * @memberof FileUploadService
	 */
	public removeErrorFiles(type: FileUploadType): IFileUpload[] {
		const files = this.getFileUploads()[type];
		const errorFiles = files?.filter((file) => file.status === FileUploadStatusEnum.error);
		errorFiles?.forEach((file) => this.removeSource(file));
		return errorFiles;
	}

	/**
	 * Removes all the files from the BE except selfie
	 *
	 * @private
	 * @param {IFileUploads} uploads
	 * @return {*}  {IFileUploads}
	 * @memberof FileUploadService
	 */
	private removeAllButSelfie(uploads: IFileUploads): IFileUploads {
		let resultUploads = {};
		// Selfie is not deleted
		if (uploads[FileUploadType.selfie]) {
			resultUploads[FileUploadType.selfie] = uploads[FileUploadType.selfie];
		}
		return resultUploads;
	}

	/**
	 * Removes all the files from the BE except selfie
	 *
	 * @return {*}  {Observable<IFileUploads>}
	 * @memberof FileUploadService
	 */
	public deleteUploads(): Observable<IFileUploads> {
		return this.mobileService
			.deleteUploadedDocument(
				this.loanAppService.loanApplicationId,
				this.loanAppService.getCurrentApplicant().applicantIndex
			)
			.pipe(
				tap((rsp) => {
					if (rsp?.success) {
						const resultUploads = this.removeAllButSelfie(this.getFileUploads());
						this.setFileUploads(resultUploads);
					}
				}),
				map(() => this.getFileUploads())
			);
	}

	/**
	 * Removes all the files from service except selfie
	 *
	 * @private
	 * @memberof FileUploadService
	 */
	public resetFilesExceptSelfie(): void {
		const uploads = this.getFileUploads();
		let resultUploads = {};
		// Selfie is not deleted
		if (uploads[FileUploadType.selfie]) {
			resultUploads[FileUploadType.selfie] = uploads[FileUploadType.selfie];
		}
		this.setFileUploads(resultUploads);

		const typeUploads: FileUploadType[] = this.sessionStorageService.get(this.currentFileTypeStorageKey);
		if (typeUploads?.includes(FileUploadType.selfie)) {
			this.sessionStorageService.set(this.currentFileTypeStorageKey, [FileUploadType.selfie]);
		} else {
			this.sessionStorageService.set(this.currentFileTypeStorageKey, []);
		}
	}

	/**
	 * delete FileUploads using V2 API
	 *
	 * @return {*}  {Observable<IFileUploads>}
	 * @memberof FileUploadService
	 */
	public deleteUploadsV2(): Observable<IFileUploads> {
		return this.mobileService
			.deleteUploadedDocumentV2(
				this.loanAppService.loanApplicationId,
				this.loanAppService.getCurrentApplicant().applicantIndex
			)
			.pipe(
				tap((rsp) => {
					if (rsp?.success) {
						this.resetFilesExceptSelfie();
					}
				}),
				map(() => this.getFileUploads())
			);
	}

	private findUploadById(uploads: IFileUploads, id: string): IFileUpload {
		let upload: IFileUpload;
		const keys = Object.keys(uploads);
		for (const key of keys) {
			const typeList = uploads[key];
			upload = typeList.find((file) => file?.ocrInfo?.documentUploadId === String(id));
			if (upload) {
				break;
			}
		}
		return upload;
	}

	private updateOcrStatus(upload: IDocumentStatus): IFileUpload {
		const files = this.getFileUploads();
		let file = this.findUploadById(files, upload.documentId);
		if (file) {
			const ocrInfo = {
				createdDate: upload.createdDate,
				criteriaMet: upload.criteriaMet,
				documentSide: upload.documentSide,
				documentStatus: upload.status,
				documentUploadId: String(upload.documentId),
				messages: upload.messages,
				success: true
			};
			this.updateSource({ ...file, ocrInfo });
		} else {
			file = this.createFileInfoFromUploadInfo(upload);
			this.updateSource(file);
		}
		return file;
	}

	private createFileInfoFromUploadInfo(upload: IDocumentStatus): IFileUpload {
		const fileTypeMap = {
			[FileTypeEnum.bank]: FileUploadType.bank,
			[FileTypeEnum.income]: FileUploadType.income,
			[FileTypeEnum.addresses]: FileUploadType.addresses,
			[FileTypeEnum.identification]: FileUploadType.identification
		};
		const docSideMap = {
			[DocumentSideEnum.front]: FileSideEnum.front,
			[DocumentSideEnum.back]: FileSideEnum.back,
			[DocumentSideEnum.none]: FileSideEnum.front
		};
		const statusMap = {
			[FileStatusEnum.pending]: FileUploadStatusEnum.finished,
			[FileStatusEnum.complete]: FileUploadStatusEnum.finished,
			[FileStatusEnum.review]: FileUploadStatusEnum.finished
		};

		return {
			type: fileTypeMap[upload.documentType],
			file: null,
			classification: upload.documentClassification,
			country: upload.country,
			side: docSideMap[upload?.documentSide],
			id: uuidv4(),
			name: upload.fileName,
			status: statusMap[upload.status],
			createdDate: upload.createdDate || 0,
			ocrInfo: {
				createdDate: upload.createdDate,
				criteriaMet: upload.criteriaMet,
				documentSide: upload.documentSide,
				documentStatus: upload.status,
				documentUploadId: String(upload.documentId),
				fileName: upload.fileName,
				messages: upload.messages,
				success: true
			}
		};
	}

	/**
	 * Create file entries into the service from the Status API.
	 *
	 * @param {IDocumentStatus[]} uploads
	 * @memberof FileUploadService
	 */
	public reconcileFileUploads(uploads: IDocumentStatus[]): void {
		this.backEndOverride(uploads)?.forEach((upload) => {
			const file = this.updateOcrStatus(upload);
			if (!file) {
				this.updateSource(this.createFileInfoFromUploadInfo(upload));
			}
		});
	}

	private updateAllOcrStatus(uploads: IDocumentStatus[]): IDocumentStatus[] {
		uploads?.forEach((upload) => this.updateOcrStatus(upload));
		return uploads;
	}

	private isAnyOcrPending(uploads: IDocumentStatus[]): boolean {
		return uploads?.some((item) => item.status === FileStatusEnum.pending);
	}

	/**
	 * For income Bank_Statement
	 * Override the status if some criteria are met.
	 * Override the message if status is review.
	 *
	 * @private
	 * @param {IDocumentStatus[]} files
	 * @return {*}  {IDocumentStatus[]}
	 * @memberof FileUploadService
	 */
	private incomeBankStatementOverride(files: IDocumentStatus[]): IDocumentStatus[] {
		return files?.map((file) => {
			if (
				file?.documentType === FileTypeEnum.income &&
				file?.documentClassification === 'BANK_STATEMENT' &&
				file.status !== FileStatusEnum.pending
			) {
				// if criteriaMet array has zeroToThirtyDays or thirtyOneToSixtyFiveDays then mark status complete
				const status = file?.criteriaMet?.some((f) => incomeBankStatementCriteria.some((b) => b === f))
					? FileStatusEnum.complete
					: file?.status;

				// if status is review override BE message
				const msg = this.languageService.translate(
					'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.incomeBankStatementBEOverrideMsg'
				);
				const messages = status === FileStatusEnum.review ? [msg] : file?.messages;

				return { ...file, status, messages };
			} else {
				return file;
			}
		});
	}

	/**
	 * If there are frontBack files and one is in review make sure the second file is also in review.
	 *
	 * @private
	 * @param {IDocumentStatus[]} files
	 * @return {*}  {IDocumentStatus[]}
	 * @memberof FileUploadService
	 */
	private identificationBackFrontOverride(files: IDocumentStatus[]): IDocumentStatus[] {
		const uniqueCreateDate = files.reduce((acc, file) => {
			if (file?.documentType === FileTypeEnum.identification) {
				const key = `${file.createdDate}`;
				acc[key] = acc[key] ? [...acc[key], file] : [file];
			}
			return acc;
		}, {});
		const pairs = Object.keys(uniqueCreateDate)
			.filter((key) => uniqueCreateDate[key].length === 2)
			.reduce((acc, key) => {
				acc[key] = uniqueCreateDate[key];
				return acc;
			}, {});
		const newFiles = files.map((file) => {
			if (file?.documentType === FileTypeEnum.identification && file.status === FileStatusEnum.complete) {
				const key = `${file.createdDate}`;
				const pair = pairs[key]?.find((f) => f.documentId !== file.documentId);
				return pair?.status === FileStatusEnum.review ? { ...file, status: FileStatusEnum.review } : file;
			} else {
				return file;
			}
		});

		return newFiles;
	}

	private backEndOverride(files: IDocumentStatus[]): IDocumentStatus[] {
		if (isEmpty(files)) {
			const resultUploads = this.removeAllButSelfie(this.getFileUploads());
			this.setFileUploads(resultUploads);
			return files;
		} else {
			let updatedFiles = this.incomeBankStatementOverride(files);
			updatedFiles = this.identificationBackFrontOverride(updatedFiles);

			return updatedFiles;
		}
	}

	/**
	 * Get current status of OCR and update the message.
	 * This is used to update the language when it changes
	 *
	 * @private
	 * @param {FileUploadType} [type=null]
	 * @return {*}  {Observable<any>}
	 * @memberof FileUploadService
	 */
	private updateOcrReviewMessage(type: FileUploadType = null): Observable<IDocumentStatus[]> {
		const getDocumentStatus$ = this.mobileService.getDocumentsStatus(
			type ? FileTypeEnum[type] : null,
			this.loanAppService.loanApplicationId,
			this.loanAppService.getCurrentApplicant().applicantIndex
		);
		return getDocumentStatus$.pipe(
			map((rsp: IDocumentStatus[]) => this.backEndOverride(rsp)),
			map((rsp: IDocumentStatus[]) => this.updateAllOcrStatus(rsp))
		);
	}

	private setOcrPendingToComplete(files: IFileUploads): void {
		Object.keys(files).forEach((key) => {
			const typeList = files[key];
			typeList.forEach((file) => {
				if (file?.ocrInfo?.documentStatus === FileStatusEnum.pending) {
					const ocrInfo = { ...file.ocrInfo, documentStatus: FileStatusEnum.complete };
					this.updateSource({ ...file, ocrInfo });
				}
			});
		});
	}

	/**
	 * Call status api to get the status of the uploaded documents until
	 * all the documents are COMPLETE or timeout
	 *
	 * @private
	 * @param {FileUploadType} [type=null]
	 * @return {*}  {Observable<any>}
	 * @memberof FileUploadService
	 */
	private pollUploadStatus(type: FileUploadType = null): Observable<IDocumentStatus[]> {
		const getDocumentStatus$ = this.mobileService.getDocumentsStatus(
			type ? FileTypeEnum[type] : null,
			this.loanAppService.loanApplicationId,
			this.loanAppService.getCurrentApplicant().applicantIndex
		);

		return timer(0, this.frequency).pipe(
			concatMap(() => getDocumentStatus$),
			map((rsp: IDocumentStatus[]) => this.backEndOverride(rsp)),
			map((rsp: IDocumentStatus[]) => this.updateAllOcrStatus(rsp)),
			pairwise(),
			first(([prev, curr]) => {
				if (isEmpty(curr)) {
					throw new Error('op: empty');
				}
				// if a file is added restart the timeout by throwing an error
				// and catching it in the retry.
				if (curr?.length > prev?.length) {
					throw new Error('op: file added');
				}

				return !this.isAnyOcrPending(curr);
			}),
			map(([prev, curr]) => curr),
			timeout(this.duration),
			retry({
				delay: (error: any, retryCount: number) => {
					// If a file was added restart our timer.
					if (error.message === 'op: file added') {
						return timer(2000);
					}
					throw error;
				}
			}),
			catchError((error) => {
				if (error instanceof TimeoutError) {
					this.setOcrPendingToComplete(this.getFileUploads());
				}

				return of();
			})
		);
	}
}
